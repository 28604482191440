// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Cx from "rescript-classnames/src/Cx.res.js";

var container = Cx.cx([
      "p-4",
      "rounded",
      "border",
      "border-transparent",
      "mb-5"
    ]);

var error = Cx.cx([
      "text-red-600",
      "bg-red-100",
      "border-red-400"
    ]);

var info = Cx.cx([
      "text-blue-600",
      "bg-blue-100",
      "border-blue-400"
    ]);

var success = Cx.cx([
      "text-green-600",
      "bg-green-100",
      "border-green-400"
    ]);

var transparent = Cx.cx([
      "text-gray-700",
      "bg-transparent"
    ]);

var warning = Cx.cx([
      "text-yellow-900",
      "bg-yellow-50",
      "border-yellow-400"
    ]);

var linkTransparent = Cx.cx([
      "font-medium",
      "hover:text-teal"
    ]);

var linkError = Cx.cx([
      "font-medium",
      "hover:text-blue-400"
    ]);

var linkWarning = Cx.cx([
      "font-medium",
      "hover:text-orange-600"
    ]);

var linkInfo = Cx.cx([
      "font-medium",
      "hover:text-purple-400"
    ]);

var linkSuccess = Cx.cx([
      "font-medium",
      "hover:text-purple-400"
    ]);

export {
  container ,
  error ,
  info ,
  success ,
  transparent ,
  warning ,
  linkTransparent ,
  linkError ,
  linkWarning ,
  linkInfo ,
  linkSuccess ,
}
/* container Not a pure module */
